import { KEAUMLClass, UMLAttribute, UMLOperation, UMLVisibility } from "@kea-mod/jointjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModalCard } from "./CustomModalCard";
import { EditUMLClassModalAttributesForm } from "./EditUMLClassModalAttributesForm";
import { EditUMLClassModalIdentifierForm } from "./EditUMLClassModalIdentifierForm";
import { EditUMLClassModalOperationsForm } from "./EditUMLClassModalOperationsForm";
import { HandoverDirection } from "@kea-mod/jointjs/components/KEAUMLClass";

interface Props {
  setisActive(successfull: boolean): void;
  isActive: boolean;
  node: joint.dia.Cell | undefined;
}

export const EditUMLClassModal = ({ setisActive, isActive, node }: Props) => {
  const inputElement = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [identifier, setIdentifier] = useState<string>("");
  const [attributes, setAttributes] = useState<UMLAttribute[]>([]);
  const [operations, setOperations] = useState<UMLOperation[]>([]);

  const [deleteAttributes, setDeleteAttributes] = useState<number[]>([]);
  const [deleteOperations, setDeleteOperations] = useState<number[]>([]);

  const initializeNodeData = useCallback(() => {
    if (node?.get("type") === "kea.UMLClass") {
      setName((node as KEAUMLClass).getClassName());
      setIdentifier((node as KEAUMLClass).getClassIdentifier());
      setAttributes([...((node as KEAUMLClass).getAttributes() || [])]);
      setOperations([...((node as KEAUMLClass).getOperations() || [])]);
    } else {
      setName("");
      setIdentifier("");
      setAttributes([]);
      setOperations([]);
    }
    setDeleteAttributes([]);
    setDeleteOperations([]);
  }, [node]);

  useEffect(() => {
    if (isActive) {
      initializeNodeData();
    }
  }, [isActive, initializeNodeData]);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, [isActive]);

  const setIsActiveWrapper = useCallback((): void => {
    initializeNodeData();
    setisActive(false);
  }, [initializeNodeData, setisActive]);

  const assignClassNode = useCallback((): void => {
    (node as unknown as KEAUMLClass).setClassName(name);
    (node as unknown as KEAUMLClass).setClassIdentifier(identifier);
    deleteAttributes.forEach((index) => {
      (node as unknown as KEAUMLClass).deleteAttribute(index);
    });
    deleteOperations.forEach((index) => {
      (node as unknown as KEAUMLClass).deleteOperation(index);
    });
    attributes.forEach((attribute, index) => {
      (node as unknown as KEAUMLClass).updateAttribute(attribute, index);
    });
    operations.forEach((operation, index) => {
      (node as unknown as KEAUMLClass).updateOperation(operation, index);
    });
    setisActive(true);
  }, [attributes, identifier, name, node, operations, deleteAttributes, deleteOperations, setisActive]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === "Enter" && isActive) {
        assignClassNode();
      }
      if (event.key === "Escape" && isActive) {
        setIsActiveWrapper();
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [assignClassNode, isActive, setIsActiveWrapper]);

  const addAttribute = (e: React.MouseEvent) => {
    e.preventDefault();
    const att: UMLAttribute = {
      visibility: UMLVisibility.public,
      attributeName: "",
      attributeType: "",
      multiplicity: "",
      defaultValue: "",
      isStatic: false,
      properties: {
        isComposite: false,
        isDerived: false,
        isDerivedUnion: false,
        isReadOnly: false,
        isStatic: false,
        isUnique: false,
      },
    };
    setAttributes([...attributes, att]);
  };

  const deleteAttribute = (index: number) => {
    setAttributes(attributes.filter((_, i) => i !== index));
    setDeleteAttributes([...deleteAttributes, index]);
  };

  const addOperation = (e: React.MouseEvent) => {
    e.preventDefault();
    const operat = {
      visibility: UMLVisibility.public,
      operationName: "",
      operationParameters: [],
      returnType: "",
      hasProperties: false,
      isOrdered: false,
      isQuery: false,
      isUnique: false,
    };
    setOperations([...operations, operat]);
  };

  const deleteOperation = (index: number) => {
    setOperations(operations.filter((_, i) => i !== index));
    setDeleteOperations([...deleteOperations, index]);
  };

  const addOperationParameter = (index: number) => {
    const newOperations = [...operations];
    const newOperation = { ...newOperations[index] };
    newOperation.operationParameters = [
      ...(newOperation.operationParameters || []),
      {
        handoverDirection: HandoverDirection.IN,
        parameterName: "",
        type: "",
        multiplicity: "",
        defaultValue: "",
        properties: {
          ordered: false,
          readOnly: false,
          in: false,
          out: false,
          inout: false,
          redefines: false,
        },
      },
    ];
    newOperations[index] = newOperation;
    setOperations(newOperations);
  };

  const deleteOperationParameter = (index: number, paramIndex: number) => {
    const newOperations = [...operations];
    const newOperation = { ...newOperations[index] };
    newOperation.operationParameters = newOperation.operationParameters.filter((_, i) => i !== paramIndex);
    newOperations[index] = newOperation;
    setOperations(newOperations);
  };

  return (
    <CustomModalCard
      setisActive={setIsActiveWrapper}
      isActive={isActive}
      header={t("uml_modal_header") as string}
      body={
        <>
          <div className="content">
            <EditUMLClassModalIdentifierForm
              isActive={isActive}
              name={name}
              setName={setName}
              identifier={identifier}
              setIdentifier={setIdentifier}
            />
          </div>

          <div className="divider is-left is-info">{t("uml_modal_attributes")}</div>
          <div className="content">
            <EditUMLClassModalAttributesForm
              attributes={attributes}
              setAttributes={setAttributes}
              deleteAttribute={deleteAttribute}
            />
          </div>

          <div className="divider is-left is-info">{t("uml_modal_operations")}</div>
          <EditUMLClassModalOperationsForm
            operations={operations}
            setOperations={setOperations}
            deleteOperation={deleteOperation}
            addOperationParameter={addOperationParameter}
            deleteOperationParameter={deleteOperationParameter}
          />
        </>
      }
      footer={
        <p className="buttons">
          <button
            type="button"
            className="button is-rounded is-light"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setIsActiveWrapper();
            }}
          >
            {t("cancel")}
          </button>
          <button type="button" className="button is-rounded is-light is-success" onClick={addAttribute}>
            {t("uml_modal_attributes_add")}
          </button>
          <button type="button" className="button is-rounded is-light is-success" onClick={addOperation}>
            {t("uml_modal_operations_add")}
          </button>
          <button
            type="button"
            className="button is-rounded is-primary"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              assignClassNode();
            }}
          >
            {t("save")}
          </button>
        </p>
      }
    />
  );
};
